<template>
  <div id="glossary-page">
    <div class="page-header glossary-header row w-100 align-items-end mx-0 mb-4">
      <div class="title col-12 col-lg-8 mx-auto pb-2">
        <h3 class="text-white">{{ $t("telestia.title.glossary") }}</h3>
      </div>
    </div>

    <!-- Loading state -->
    <div v-if="pendingComponentInit" class="loading-state d-flex justify-content-center">
      <b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="primary" />
    </div>

    <!-- Loaded state -->
    <template v-else>

      <div v-if="error" class="row mx-0">
        <div class="col-12 col-lg-8 offset-lg-2">
          <h4 class="mb-2">{{ error }}</h4>
        </div>
      </div>

      <div v-else class="row mx-0">
        <div class="col-12 col-lg-8 mx-auto">
          <div class="row mb-1">
            <div class="col"><h5>{{ $t("telestia.title.search_options") }}</h5></div>
          </div>
          <div class="row">
            <div class="col">
              <b-form class="d-flex align-items-end flex-wrap justify-content-between">
                <div class="d-flex align-items-end mb-2 mb-lg-0">
                  <div>
                    <label for="search-input">{{ $t("telestia.title.term_contains") }}</label>
                    <b-form-input
                        id="search-input"
                        class="mr-sm-2 mb-sm-0 mt-1"
                        v-model="termToSearch"
                        @keydown.enter.prevent="searchTerm"
                    ></b-form-input>
                  </div>
                  <b-button variant="primary" class="ml-2" @click="searchTerm" :disabled="termToSearch.length<2">{{ $t("telestia.title.search") }}</b-button>
                </div>

                <div class="d-flex flex-column align-items-start">
                  <label for="alphabet">{{ $t("telestia.title.term_starts_with") }}</label>
                  <div class="d-flex flex-wrap"><a v-for="letter in (alphabets[appLanguage]||alphabets.en)" :key="letter" class="mouse-pointer mr-2" @click="searchTermsByLetter(letter)">{{ letter }}</a></div>
                </div>

                <div class="d-flex flex-column align-items-start">
                  <label for="course-select">Course</label>
                  <b-form-select v-model="selectedCourse" id="course-select" :options="courseOptions"></b-form-select>
                </div>

                <div class="d-flex flex-column align-items-start">
                  <label for="language-select">Language</label>
                  <b-form-select v-model="selectedLanguage" id="language-select" :options="languageOptions"></b-form-select>
                </div>
              </b-form>
            </div>
          </div>

          <div v-if="termsToView.length > 0" class="row my-5">
            <div class="col">
              <div class="row border-top border-bottom py-3">
                <div class="col-4 font-weight-bold">{{ $t("telestia.table.header.word") }}</div>
                <div class="col-4 font-weight-bold">{{ $t("telestia.table.header.image") }}</div>
                <div class="col-4 font-weight-bold">{{ selectedLanguageText }}</div>
              </div>

              <div v-if="pendingImageLoading" class="loading-state d-flex justify-content-center mt-2">
                <b-spinner style="width: 1.5rem; height: 1.5rem;" type="grow" variant="primary" />
              </div>
              <template v-else>
                <div class="row border-bottom py-2" v-for="(term, index) in termsToView" :style="{ 'background-color': backgroundColor(index) }">
                  <div class="col-4">{{ term.text }}</div>
                  <div class="col-4">
                    <img :src="term.image.src" :alt="term.text" class="img-fluid term-image" width="150" height="150" @click="zoomIn(term.image.src)"/>
                  </div>
                  <div class="col-4">{{ term.translation }}</div>
                </div>
              </template>
            </div>
          </div>

          <div v-if="noTermsRetrieved" class="my-5 py-3 border-top">
            {{ $t("telestia.message.no_results") }}
          </div>

          <b-modal ref="zoom-modal" centered hide-footer hide-header>
            <div class="d-block text-center">
              <img :src="zoomedInImageSrc" alt="magnified-image" class="img-fluid"/>
            </div>
          </b-modal>
        </div>
      </div>

    </template>

  </div>
</template>

<script>
import assets from '../../shared/helpers/assets';

export default {
  name: "glossary",

  data() {
    return {
      pendingComponentInit: false,
      pendingImageLoading: false,
      glossary: {},
      error: '',

      searchingBy: '',
      termToSearch: '',
      selectedCourse: '',
      courseOptions: [
        { value: '', text: 'All' },
        { value: 'pm', text: 'Pattern Making' },
        { value: 'pgr', text: 'Pattern Grading' },
        { value: 'fd', text: 'Fashion Design' },
        { value: 'fe', text: 'Fashion Express' },
        { value: 'se', text: 'Sewing' }
      ],
      selectedLanguage: 'en',
      languageOptions: [
        { value: 'en', text: 'English' },
        { value: 'de', text: 'Deutsche' },
        { value: 'es', text: 'Español' },
        { value: 'fr', text: 'Français' },
        { value: 'el', text: 'Ελληνικά' },
        { value: 'he', text: 'עברית' },
        { value: 'no', text: 'Norsk' }
      ],
      alphabets: {
      	en: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      	el: ['Α', 'Β', 'Γ', 'Δ', 'Ε', 'Ζ', 'Η', 'Θ', 'Ι', 'Κ', 'Λ', 'Μ', 'Ν', 'Ξ', 'Ο', 'Π', 'Ρ', 'Σ', 'Τ', 'Υ', 'Φ', 'Χ', 'Ψ', 'Ω'],
      	he: ['א' ,'ב' ,'ג' ,'ד' ,'ה' ,'ו' ,'ז' ,'ח' ,'ט' ,'י' ,'כ' ,'ל' ,'מ' ,'נ' ,'ס' ,'ע' ,'פ' ,'צ' ,'ק' ,'ר' ,'ש' ,'ת']
      },

      termsBySelectedCourse: [],
      termsToView: [],
      zoomedInImageSrc: '',
      noTermsRetrieved: false
    }
  },

  created() {
    this.fetchGlossary();
  },

  computed: {
    isOnlineBuild() { return this.$store.getters['app/isOnline'] },

    isOfflineBuild() { return this.$store.getters['app/isOffline'] },
    
    appLanguage() { return this.$store.getters['user/getSelectedLanguage']; },

    selectedLanguageText() {
      return this.languageOptions.find(l => l.value === this.selectedLanguage).text;
    },
  },

  methods: {
  	// Fetch glossary entries
    async fetchGlossary() {
      try {
        this.pendingComponentInit = true;
        this.glossary = this.isOnlineBuild
          ? await this.$store.dispatch("fetchGlossary")
          : await this.$store.getters.getGlossary;
      } catch (e) {
        console.log(e);
        this.error = 'Glossary could not be retrieved.. Something went wrong';
      } finally {
        this.pendingComponentInit = false;
      }
    },

		// Search for glossary entries that return a term
    async searchTerm() {
      try {
		    this.searchingBy = this.termToSearch;
		    this.termsBySelectedCourse = this.filterTermsByCourse();

		    this.termsToView = this.termsBySelectedCourse
		        ? this.termsBySelectedCourse
		            .filter(t => t.language === this.appLanguage && t.text.toLowerCase().includes(this.termToSearch.toLowerCase()))
		            .map(t => { return { mainId: t.mainId, image: { name: t.image, src: '' }, text: t.text } })
		        :	[];

		    this.getTermsTranslations();
		    this.noTermsRetrieved = this.termsToView.length === 0;

        await this.fetchImageSources();
      } catch (e) {
      	console.log(e);
        this.showErrorAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.glossary'),
        });
      }
    },

		// Search for glossary entries that start with a specific letter
    async searchTermsByLetter(letter) {
      try {
		    this.searchingBy = letter;
		    this.termsBySelectedCourse = this.filterTermsByCourse();

		    this.termsToView = this.termsBySelectedCourse
		        ? this.termsBySelectedCourse
		            .filter(t => t.language === this.appLanguage && t.text.toLowerCase().startsWith(letter.toLowerCase()))
		            .map(t => { return { mainId: t.mainId, image: { name: t.image, src: '' }, text: t.text } })
		        : [];

		    this.getTermsTranslations();
		    this.noTermsRetrieved = this.termsToView.length === 0;

        await this.fetchImageSources();
      } catch (e) {
        console.log(e);
        this.showErrorAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.glossary'),
        });
      }
    },

    // Keep glossary entries by course
    filterTermsByCourse() {
      if (this.selectedCourse) {
        const glossaryCourseEntry = this.glossary[this.courseOptions.find(c => c.value === this.selectedCourse).text];
        return glossaryCourseEntry ? glossaryCourseEntry.data : undefined;
      }
      let filteredTerms = [];
      Object.keys(this.glossary).forEach(c => filteredTerms = filteredTerms.concat(this.glossary[c].data));
      return filteredTerms;
    },

		// Get glossary entries translations
    getTermsTranslations() {
      this.termsToView
          .forEach(t => {
            const translatedGlossaryEntry = this.termsBySelectedCourse.find(r => r.mainId === t.mainId && r.language === this.selectedLanguage)
            if (translatedGlossaryEntry) { t.translation = translatedGlossaryEntry.text }
          });
    },

		// Fetch glossary image sources for the entries that are shown to the user
    async fetchImageSources() {
      try {
        this.pendingImageLoading = true;

        if (this.isOnlineBuild) {
          const requests = [];
          this.termsToView.forEach(t => {
            requests.push(this.$store.dispatch("glossary/fetchGlossaryImage", t.image.name));
          })
          const results = await Promise.allSettled(requests);

          this.termsToView.forEach((t, index) => {
            if (results[index].status !== 'rejected') { t.image.src = results[index].value ? results[index].value : this.getImageBase64(''); }
            else { t.image.src = this.getImageBase64(''); }
          })

          return Promise.resolve();
        }

        this.termsToView.forEach(t => t.image.src = this.getImageBase64(t.image.name));
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e)
      } finally {
        this.pendingImageLoading = false;
      }
    },

		// Get image base64 encoded source
    getImageBase64(imageName) {
      return assets.getImageData('glossary', imageName);
    },

		// Zoom in selected image
    zoomIn(imageSrc) {
      this.zoomedInImageSrc = imageSrc;
      this.$refs['zoom-modal'].show();
    },

		// Background color helper
    backgroundColor(index) {
      if (index % 2 === 0) { return '#F2F2F2'; }
      return 'white';
    },

    showErrorAlert({ title, text }) {
    	this.$swal({ title, text, icon: 'error', timer: 5000, button: true });
    }
  },

  watch: {
  	appLanguage() { this.termsToView = []; },
  	
    selectedLanguage(n, o) {
      if (this.searchingBy) { this.getTermsTranslations(); }
    },

    selectedCourse(n, o) {
      if (this.searchingBy.length === 1) { this.searchTermsByLetter(this.searchingBy); }
      else if (this.searchingBy.length > 1) { this.searchTerm(); }
    }
  }
}
</script>

<style>
.glossary-header {
  background-image: url(../../assets/images/page-header/glossary.jpg);
}
.term-image {
  cursor: zoom-in;
}
</style>



